//test
const logotext = "Samuel";
const meta = {
  title: "Samuel Gilles",
  description:
    "Je suis Samuel Gilles _ en recherche d'une alternance en tant que developpeur front-end",
};

const introdata = {
  title: "Bonjour, je suis Samuel Gilles",
  animated: {
    first: "Je suis en formation",
    second: "de developpeur d'application",
    third: "à l'IBM Académie",
  },
  description:
    "Je vous remercie vivement de m’accorder une alternance dans votre entreprise ! J’accepte avec grand plaisir. Bon d’accord, nous n’en sommes pas encore là, alors je vous laisse explorer  mon portfolio afin de faire votre choix. Bonne lecture !",
  your_img_url: "",
};

const dataabout = {
  title: "Présentation",
  aboutme:
    "Actuellement étudiant en développement d’applications spécialisées en IA à l’Académie IBM, je suis à la recherche d’une alternance de 18 mois .Après avoir obtenu une licence en communication, je me suis tourné vers la formation de développeur d'application afin de joindre un domaine qui m’attire et me passionne.",
  aboutme2:
    " Polyvalent, engage et réfléchi, je possède une forte capacité d’adaptation et j’aime travailler en équipe.",
};
const worktimeline = [
  {
    jobtitle: "Casino",
    date: "2021 - Mai & 2022 - Juin",
  },
  {
    jobtitle: "Opinaka",
    date: "2021 - Avril",
  },
  {
    jobtitle: "E3D Environnement",
    date: "2018 - Juin",
  },
  {
    jobtitle: "BNP PARIS BAS",
    date: "2017 - Janvier & 2016 - Aout",
  },
];

const skills = [
  {
    name: "Programmation front-end",
    skills:
      " Frameworks Javascript/ ReactJS HTML / CSS/ Bootstrap/ React Native/ PHP",
  },
  {
    name: "Project Management",
    skills: "Trello/ Kanban/ Formation Soft-Skills/ Formation méthodes Agile",
  },
  {
    name: "Outil IA",
    skills: "Pandas/ Scikit learn",
  },
  {
    name: "Stack back-end",
    skills: " Sql/ Php/ Python/ Node js/ MongoDB",
  },
  {
    name: "Outils de communications",
    skills: "Suite Adobe / Stratégie de Communication",
  },
];

const services = [
  {
    title: "En cour : Formation Developpeur d'application (Bac+4)",
    description:
      "Formation qui a débuté en Décembre 2022 au sein de l'academie IBM",
  },
  {
    title: "Licence en Information et Communication",
    description:
      "Diplôme obtenu à l'université Paul Valéry en 2021 avec la mention Assez bien. Poursuite d'étude en Master Communication Publique Assiocative et Culturel suivis d'une reconversion dans le domaine de la programation",
  },
  {
    title: "Baccalauréat ES (Economique et Social)",
    description:
      "Diplôme obtenue est 2015 au Lycée Leconte de Lisle à l'ile de la Réunion en 2015 avec la mention Assez Bien",
  },
];

const dataportfolio = [
  {
    img: "https://gilles.needemand.com/portfolio/projet/Sea.png",
    description: "Player audio",
    link: "https://gilles.needemand.com/portfolio/projet/Projet/sea.html",
  },
  {
    img: "https://gilles.needemand.com/portfolio/projet/react_app.png",
    description: "Site React Pokemon (Projet perso)",
    link: "https://gilles.needemand.com/portfolio/Pokemon",
  },
  {
    img: "https://gilles.needemand.com/portfolio/projet/PNG_5.png",
    description: "Short Video edited",
    link: "https://gilles.needemand.com/portfolio/projet/Montage_Premiere.mp4",
  },
  {
    img: "https://gilles.needemand.com/portfolio/projet/Django.png",
    description: "Projet en Django",
    link: "https://sunderzz.pythonanywhere.com/",
  },
  {
    img: "https://gilles.needemand.com/static/media/earth-11595.bdf9e7d229596052018c.jpg",
    description: "Data Visualisation",
    link: "https://gilles.needemand.com/portfolio/Visualisation/",
  },
];

const contactConfig = {
  YOUR_EMAIL: "samuelgillespro@hotmail.com",
  YOUR_FONE: "0649307055",
  description: "",

  YOUR_SERVICE_ID: "service_id",
  YOUR_TEMPLATE_ID: "template_id",
  YOUR_USER_ID: "user_id",
  img: "./Fichier/qrcode.png",
};

const socialprofils = {
  github: "https://github.com/SunderZz",
  linkedin: "https://www.linkedin.com/in/samuel-gilles-59bab01ba/",
};
export {
  meta,
  dataabout,
  dataportfolio,
  worktimeline,
  skills,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
};
